.dashboard-organization-container {
    box-sizing: border-box;
    margin: auto;
}

.organization-dashboard-sub-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dashboard-organization-heading {
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: var(--color-gray-900);
}

.dashboard-organization-heading-gray {
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: #6B7280;
}

.organization-member-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    gap: 1rem;
    margin-top: 1.5rem;
}

.dashboard-organization-table-container {
    padding: 1.5rem;
    margin-top: 1.5rem;
}

.dashboard-organization-table-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-organization-table-header-container {
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
    color: var(--color-gray-900);
}

.dashboard-organization-table-header-container button {
    color: var(--color-white);
}

.dashboard-organization-member-info {
    display: flex;
    align-items: center;
}

.member-info-avatar {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 10px;
    background-color: var(--color-dark-green);
}

.dashboard-organization-view-link {
    color: var(--color-dark-green);
    text-decoration: underline;

    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-14);
    font-weight: 400;
    line-height: 21px;
    text-align: left;
}

.table-action-icons {
    display: flex;
    gap: 0.625rem;
}

.action-icon {
    cursor: pointer;
}

.not-found-organization-text {
    text-align: center;
    padding: 0.8rem;
    margin: 0;
}