.dashboard-pannel-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  height: 12.5rem;
  margin-bottom: 1.5rem;
}

.dashboard-pannel-container > * {
  flex-grow: 1;
}

.dashboard-pannel-badge-container  {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.dashboard-pannel-container .dashboard-pannel {
  max-width: 500px;
}

.dashboard-home-search button[type="submit"] {
  width: 250px;
}

.dashboard-page-subtitle {
  color: var(--color-grey-400) !important;
}


.dashboard-home-search h2 {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--color-grey-400);
  font-family: var( --default-font-family-Inter);
}