.chat-research-section {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.chat-research-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  gap: 2rem;
  text-align: center;
}

.chat-research-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.chat-research-content::-webkit-scrollbar {
  display: none;
}

.chat-research-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 1rem;
}

.chat-research-header img {
  height: 3.75rem;
  width: 3.75rem;
  margin: auto;
}

.chat-research-header h2 {
  font-family: var(--default-font-family-Syne);
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.4rem;
  text-align: center;
  color: var(--color-gray-800);
  padding: 0;
  margin: 0;
}

.chat-research-header p {
  font-family: var(--default-font-family-Dm-Sans);
  font-size: var(--default-font-size-18);
  font-weight: 400;
  line-height: 1.688rem;
  text-align: center;
  color: var(--color-gray-800);
}
