.pricing-plan-main-container {
  display: flex;
  flex-direction: column;
  gap: 124px;
  padding: 5.625rem 2rem;
  background: url("@assets/images/atticus/backgrounds/pricing/pricing-left-background.svg"), url("@assets/images/atticus/backgrounds/auth/auth-right-background.svg");
  background-repeat: no-repeat;
  background-size: 60% 60%, 50% 70%;
  background-position: top left, top right;
  width: 100%;
}

.pricing-plan-header-section {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 90rem;
  margin: auto;
}

.pricing-plan-title-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 76rem;
}

.pricing-plan-header-section span {
  border-radius: 1rem;
  padding: 0.25rem 0.75rem;
  background: #62c7a321;
  font-weight: 400;
  font-size: var(--default-font-size-16);
  font-family: var(--default-font-family-Dm-Sans);
  line-height: 1.5rem;
  text-align: center;
  color: #5bc0b3;
}

.pricing-plan-title {
  font-weight: 600;
  font-size: 4.5rem;
  line-height: 4.625rem;
  text-align: center;
  font-family: var(--default-font-family-Syne);
  color: var(--color-black);
  margin-bottom: 0;
}

.pricing-plan-header-section p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: var(--default-font-size-18);
  font-family: var(--default-font-family-Dm-Sans);
  line-height: 1.688rem;
  text-align: center;
  color: var(--color-black);
}

.pricing-plan-price-card-section {
  width: 100%;
  max-width: 66.375rem;
  border-radius: 1rem;
  border: 1px solid var(--Grays-100, #f2f4f7);
  padding: 2.5rem 2.75rem;
  background-color: var(--color-white);
  margin: auto;
}

.pricing-plan-card {
  display: flex;
  gap: 5rem;
  align-items: center;
}

.pricing-plan-left-side {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-width: 32rem;
}

.pricing-plan-left-side h3 {
  margin-bottom: 0;
  font-family: var(--default-font-family-Syne);
  font-size: 3rem;
  line-height: 4.625rem;
  font-weight: 600;
  color: var(--color-gray-900);
}

.pricing-plan-left-side .pricing-plan-left-side-text {
  margin-bottom: 0;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  color: var(--color-gray-500);
  width: 29.438rem;
}

.pricing-plan-price-text {
  font-weight: 600;
  font-size: 4.5rem;
  line-height: 4.625rem;
  font-family: var(--default-font-family-Syne);
  color: var(--color-gray-900);
}

.month-text {
  color: var(--color-gray-500);
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
}

.pricing-plan-text {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.4rem;
  color: var(--color-gray-900);
  font-family: var(--default-font-family-Syne);
  width: 22.813rem;
}

.pricing-plan-right-side {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.text-points-content {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.text-points-content p {
  margin-bottom: 0;
  font-family: var(--default-font-family-Dm-Sans);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  color: var(--color-gray-900);
}

.book-demo-button {
  font-weight: 700;
  font-size: var(--default-font-size-16);
  line-height: 1.5rem;
  font-family: var(--default-font-family-Dm-Sans);
  color: var(--color-black);
  width: 100%;
}

.view-all-plans-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
}

.view-all-plans-section img {
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: var(--default-font-size-16);
  line-height: 1.5rem;
  font-family: var(--default-font-family-Dm-Sans);
  text-align: center;
  color: var(--color-gray-800);
}

.included-heading {
  font-weight: 700;
  font-size: var(--default-font-size-16);
  line-height: 1.5rem;
  font-family: var(--default-font-family-Dm-Sans);
  color: var(--color-gray-900);
  margin-bottom: 0;
}


.highlight-standard-plan {
  background: var(--gradient-green-teal);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  position: relative;
}

.highlight-standard-plan::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 100%;
  height: 1.5px;
  background: var(--gradient-green-teal);
}
