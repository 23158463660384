.pricing-card {
  width: 100%;
  max-width: 23.625rem;
  height: 100%;
  max-height: 43.313rem;
  border-radius: 1rem;
  padding: 2.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border: 1px solid var(--Grays-100, hsla(216, 24%, 96%, 1));
}

.pricing-card.highlighted {
  position: absolute;
  background: var(--color-black);
  color: var(--color-white);
  top: -50px;
  box-shadow: 0px 16px 24px 0px #1E1E1E14;
}

.pricing-card.highlighted .pricing-card-title-section h3,
.pricing-card.highlighted .pricing-card-title-section p,
.pricing-card.highlighted .pricing-price,
.pricing-card.highlighted .included-heading,
.pricing-card.highlighted .month-text {
  color: inherit;
}

.pricing-card-top-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 20.625rem;
  gap: 1rem;
}

.pricing-card-title-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.pricing-card-title-section h3 {
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: var(--color-gray-900);
  margin-bottom: 0;
}

.pricing-card-title-section p {
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 400;
  font-size: var(--default-font-size-18);
  line-height: 1.688rem;
  color: var(--color-gray-500);
  margin-bottom: 0;
}

.pricing-price {
  font-family: var(--default-font-family-Syne);
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.625rem;
  color: var(--color-gray-900);
  margin-bottom: 0;
}

.contact-sales-price {
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-gray-900);
  line-height: 2.4rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.pricing-features {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.feature-item p {
  margin-bottom: 0;
}

.pricing-button-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-button {
  width: 100%;
  max-width: 8.188rem;
  height: 3.5rem;
  border: 1px solid var(--color-grey-300);
  background-color: var(--color-white);
  padding: 0rem;
  color: var(--color-black);
  font-size: var(--default-font-size-16);
  margin-top: 1.5rem;
  margin: auto;
  font-family: var(--default-font-family-Dm-Sans);
  font-weight: 700;
  line-height: 1.5rem;
  text-align: center;
}

.pricing-card.highlighted .pricing-button {
  background-color: var(--color-white);
  color: var(--color-black);
  margin-top: 1.5rem;
}
