.dashboard-pannel {
  padding: 1.2rem;
  box-sizing: border-box;
  height: 100%;
  width: 33%;
  background-color: var(--color-white);
  border-radius: 10px;
}

.dashboard-pannel-title {
  margin: 1rem 0;
}

.dashboard-pannel-subtitle {
  color: var(--color-gray-500);
}


.dashboard-pannel-link {
  font-weight: 500;
  text-decoration: underline;
  font-family: var(--default-font-family-Dm-Sans);
}

.dashboard-pannel-link svg {
  margin-left: 0.4rem;
}
