.confirmation--modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    z-index: 1000;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.confirmation--modal.open {
    opacity: 1;
    visibility: visible;
}

.confirmation--modal-content {
    background: #fff;
    padding: 1.5rem;
    border-radius: 0.75rem;
    max-width: 31.25rem;
    position: relative;
    transform: scale(0.9);
    transition: transform 0.3s ease;
    backdrop-filter: blur(100px);
}

.confirmation--modal.open .confirmation--modal-content {
    transform: scale(1);
}

.confirmation--modal-header {
    font-family: var(--default-font-family-Syne);
    font-size: 2rem;
    font-weight: 600;
    line-height: 21.6px;
    text-align: left;
    margin-bottom: 1rem;
}

.confirmation--modal-body {
    margin-bottom: 1.5rem;
}

.confirmation--modal-body p {
    font-family: var(--default-font-family-Dm-Sans);
    font-size: var(--default-font-size-18);
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: var(--Grays-500, #667085);
}

.confirmation--modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.confirmation--modal-button {
    cursor: default;
}

.confirmation--modal-button:disabled {
    color: var(--color-black);
}