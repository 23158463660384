.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-head {
    margin-left: 0.5rem;
    text-align: center;


}

.logo-head h4 {
    font-size: 1.35rem;
    font-weight: 600;
    font-family: var(--default-font-family-Syne);
}

.logo-head h4 span {
    display: block;
    font-size: 0.49rem;
    line-height: 0.5pc;
    background: var(--gradient-green-teal) !important;
    width: auto;
    padding: 0.3rem !important;
    color: var(--color-white);
    border-radius: 3px;
    text-transform: uppercase;
    font-family: var(--default-font-family-Syne);
}

.logo img {
    height: 2.4rem;
}

.nav-logo-container {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    justify-content: center;
}