.case-timeline-page {
    width: 100%;
}

.case-timeline-page-dropdown {
    display: flex;
    justify-content: flex-end;

}

.case-timeline-page-dropdown-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.retry-atticus-timeline {
    font-size: 0.7rem;
    font-family: var(--default-font-family-Dm-Sans);
    color: var(--color-white);
    border-radius: 0.3rem;
    cursor: pointer;
    align-self: stretch;
}

.case-timeline-page {
    padding: 1.25rem;
}

.case-timeline-page-dropdown {
    margin-bottom: 1.25rem;
}

.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1.5rem;
}

.timeline-line {
    position: absolute;
    left: 2rem;
    height: 100%;
    width: 1px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(152, 162, 179, 1)' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

}

.case-timeline-filled-arrow-up {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    left: 1.3rem;
    top: -1.5%;
    cursor: pointer;
    z-index: 1;
}

.case-timeline-filled-arrow-down {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    left: 1.3rem;
    top: 99%;
    cursor: pointer;
    z-index: 1;
}


.timeline-item-wrapper {
    opacity: 1;
    transition: opacity 0.5s ease;
}

.case-timeline-filled-arrow-up,
.case-timeline-filled-arrow-down {
    transition: transform 0.3s ease;
}

.timeline-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.no-data-message {
    text-align: center;
    color: #999;
    font-size: 1.25rem;
    padding: 2rem;
}