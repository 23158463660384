.motion-modal-page-1 textarea {
  display: block;
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.motion-modal-page-1 .common-button {
  color: #fff;
  width: 100%;
}

.download-motion-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  text-decoration: underline;
}

/* The disabled version: keep background white, text/border gray, no clicks */
.motion-download-disabled {
  width: 100%;
  background-color: #fff !important;
  color: var(--color-gray-500) !important;
  border-color: var(--color-gray-500) !important;
  pointer-events: none;
  cursor: not-allowed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  text-decoration: underline;
}

/* Prevent any hover/focus from changing the background color */
.motion-download-disabled:hover,
.motion-download-disabled:focus {
  background-color: #fff !important;
  color: var(--color-gray-500) !important;
  border-color: var(--color-gray-500) !important;
}

/* Dim the icon as well */
.motion-download-disabled svg {
  stroke: var(--color-gray-500) !important;
}

.download-motion-confirm-container form {
  display: flex;
  align-items: start;
  gap: 20px;
  margin-bottom: 30px;
}

.download-motion-checkbox {
  margin-top: 0.3rem;
  appearance: none;
  appearance: none;
  width: 1.8rem;
  height: 0.8rem;
  border: 1px solid var(--color-grey-400);
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s ease;
}


.download-motion-confirm-container input[type='checkbox']:checked {
  border-color: transparent;
}

.download-motion-confirm-container input[type='checkbox']:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.8rem;
  height: 0.8rem;
  background-image: url('@assets/images/atticus/icons/check-box-icon.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.download-motion-confirm-container p {
  color: var(--color-gray-500);
}

.no-click {
  pointer-events: none;
  cursor: default;
}

.motion-filing-guidelines-title {
  font-size: var(--default-font-size-18);
  font-family: var(--default-font-family-Dm-Sans);
}

.motion-filing-listing-section {
  font-size: var(--default-font-size-14);
  font-family: var(--default-font-family-Dm-Sans);
  list-style-type: disc;
  margin-left: 0.375rem;
  margin-right: 0.375rem;
  text-align: justify;
}

.motion-filing-listing {
  padding: 0.25rem;
}

.motion-filing-listing span {
  font-weight: 600;
}
