.cases-index-table {
  background-color: var(--color-white);
  width: 100%;
  border-radius: 8px;
  padding: 40px 30px;
  font-family: var(--default-font-family-Dm-Sans);
}

.cases-index-table-heading {
  font-weight: bold;
  display: flex;
}

.cases-index-table-heading > * {
  flex: 1;
  text-align: center;
}

.cases-index-table-heading > :last-child {
  text-align: right;
}

.cases-index-table-heading > :first-child {
  text-align: left;
}

.cases-index-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 40px;
  border-bottom: 1px solid var(--color-light-gray);
}

.cases-index-table-header h3{
  font-family: var(--default-font-family-Syne);
}

.cases-index-table-row:last-child {
  border-bottom: none;
}

.cases-index-table-header-buttons {
  width: 13rem;
  height: 50px;
}

.cases-index-table-row > p {
  width: 100%;
  text-align: center;
  margin: 0;
}

.cases-index-table-row > p:first-child {
  text-align: left;
}

.cases-index-table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0;
  border-bottom: 1px solid var(--color-light-gray);
}

.cases-index-table-row-status {
  text-transform: capitalize;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cases-index-table-row-status p {
  background-color: green;
  width: 100px;
  text-align: center;
  padding: 10px;
  margin: 4px 0;
  font-weight: bold;
}

.cases-index-table-row-status.open p {
  color: var(--color-dark-green-2);
  background-color: var(--color-light-green-2);
}

.cases-index-table-row-status.closed p {
  color: var(--color-dark-red);
  background-color: var(--color-light-red);
}

.cases-list-actions-container {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  margin: 0;
  gap: 0.6rem;
}

.cases-action-buttons {
  width: 1.875rem;
  height:  1.875rem;
}

.cases-action-buttons:nth-child(2) {
  width: 1.563rem;
  height:  1.563rem;
}
